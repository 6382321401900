import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { Button } from '@attentive/picnic';

interface Props {
  text: string;
  dataIntegrationsId?: string;
  disabled?: boolean;
}

export const SubmitButton: FC<Props> = ({ text, dataIntegrationsId, disabled }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Button
      type="submit"
      loading={isSubmitting}
      data-integrations-id={dataIntegrationsId}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
