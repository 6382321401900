import pick from 'lodash/pick';
import React, { FC } from 'react';

import { Stack } from '@attentive/picnic';

import { useConnectIntegrationMutation } from '../../api';
import { useIntegrationContext } from '../../hooks';
import useSetMilestone, { MilestoneKey } from '../../hooks/useSetMilestone';
import { IntegrationFormFeatureValues } from '../../types';
import { CreateUserAttributesField } from '../SettingsStandard/CreateUserAttributesFeature';

import { IntegrationForm } from './IntegrationForm';
import { SubmitButton } from './SubmitButton';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const PostPilotConfigureForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();
  const setMilestonesComplete = useSetMilestone();

  const validationConfig = {
    fields: {},
  };

  const initialValues = pick(integrationDetailValues || {}, [
    'address1PropertyId',
    'address2PropertyId',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  const customOnComplete = () => {
    setMilestonesComplete([MilestoneKey.MilestoneKeyEspIntegrationCompleted]);
    onComplete();
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useConnectIntegrationMutation}
      onComplete={customOnComplete}
    >
      <CreateUserAttributesField
        fieldName="address1PropertyId"
        label="Address1 Property"
        placeholder="Address 1 Attribute"
        subtext=""
        required={true}
      >
        {' '}
      </CreateUserAttributesField>
      <CreateUserAttributesField
        fieldName="address2PropertyId"
        label="Address2 Property"
        placeholder="Address 2 Attribute"
        subtext=""
        required={true}
      >
        {' '}
      </CreateUserAttributesField>

      <Stack direction="horizontal" spacing="$space6">
        <SubmitButton text="Install" dataIntegrationsId="PostPilot Submit Button" />
      </Stack>
    </IntegrationForm>
  );
};
