import React from 'react';

import googleContentAPILogo from '../assets/vendor_logos/googlecontentapi.png';
import {
  SetupGoogleContentApi,
  SetupGoogleContentApiSteps,
  SetupGoogleContentApiSettings,
} from '../components/SetupGoogleContentApi';
import { IntegrationContextProvider } from '../hooks';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorGoogleContentApi: Vendor = {
  logo: googleContentAPILogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  infoPagesEnabled: true,
  setupComponent(onComplete) {
    return <SetupGoogleContentApi onComplete={onComplete} />;
  },
  setupComponentInternals(onComplete, extraText, _, oAuthState) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.VENDOR_GOOGLE_CONTENT_API}>
        <SetupGoogleContentApiSteps
          onComplete={onComplete}
          extraText={extraText}
          oAuthState={oAuthState}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent() {
    return <SetupGoogleContentApiSettings />;
  },
  companyWebsite: 'https://www.google.com/intl/en_my/retail/',
  learnSubtext:
    'Google Merchant Center provides a seamless way to import your Google Product Catalog to Attentive.',
  jointValueProp:
    'Seamlessly sync your Google Product Catalog with Attentive in just a few clicks. This integration enables direct import of product data from Google Merchant Center, unlocking enhanced journey triggers, AI-driven product recommendations, and advanced audience segmentation based on real-time inventory and catalog updates.',
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Send high-performing journeys, including Back in Stock, Low Inventory, and Price Drops. Branch journeys based on product attributes or inventory - for example only sending a message if the product is in stock.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Segment customers based on purchasing and browsing activity using product data such as name, category, tag, price, and other attributes.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/30154980006420-Google-Merchant-Center#h_01J74DJ4VWQ6848M7G58HRBKPW',
      label: 'Attentive Help Center',
    },
    {
      href: 'https://developers.google.com/shopping-content/guides/quickstart/',
      label: 'Google Merchant Center docs',
    },
  ],
  whatYoullNeedItems: [
    'A Google Merchant Center account.',
    'Products uploaded to Google Merchant Center.',
    'No existing product catalog in Attentive.',
    "You're not eligible for more advanced product catalog options like our Shopify integration, other eCommerce integrations, or direct API integration.",
  ],
  disconnectText:
    'We will no longer be able to import your product catalog from Google Merchant Center.',
  disconnectConfirmationText:
    "Are you sure? If you'd like to import your product catalog using an alternate method, we may need to delete your product catalog. Contact your customer support manager for more information.",
};
