import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { CreateUserAttributesField } from './CreateUserAttributesFeature';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const PostPilotSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {},
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'address1PropertyId',
    'address2PropertyId',
    'THIRD_PARTY_PROCESSOR_FORWARDER',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  initialValues.THIRD_PARTY_PROCESSOR_FORWARDER = pick(
    initialValues.THIRD_PARTY_PROCESSOR_FORWARDER,
    ['enabled']
  );

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationFeature
        type="THIRD_PARTY_PROCESSOR_FORWARDER"
        label="Enable Journey Postback Feature"
        subtext=""
      >
        <CreateUserAttributesField
          fieldName="address1PropertyId"
          label="Address1 Property"
          placeholder="Address 1 Attribute"
          subtext=""
          required={true}
        />
        <CreateUserAttributesField
          fieldName="address2PropertyId"
          label="Address2 Property"
          placeholder="Address 2 Attribute"
          subtext=""
          required={true}
        />
      </IntegrationFeature>
      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
