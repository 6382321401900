import React from 'react';

import postpilotLogo from '../assets/vendor_logos/postpilot.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { PostPilotSettingsForm } from '../components/SettingsStandard/PostPilotSettingsForm';
import { PostPilotConfigureForm } from '../components/SetupConnectAndConfigure/PostPilotConfigureForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey } from '../types';

export const vendorPostPilot: Vendor = {
  logo: postpilotLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,

  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_POST_PILOT}
        onComplete={onComplete}
        form={({ onComplete: onFormComplete }) => (
          <PostPilotConfigureForm onComplete={onFormComplete} />
        )}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_POST_PILOT}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <PostPilotSettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
};
